@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background-color: #F9F4F0;
}

.btn1{
    @apply bg-[#CEDCFF] text-xl text-[#1F392B] py-5 px-10 rounded-full 
    hover:bg-[#EFF879] transition-all duration-300 cursor-pointer
}

.btn2{
    @apply border border-black text-xl text-[#1F392B] py-5 px-10 rounded-full 
    hover:bg-teal-950 hover:text-white transition-all duration-300 cursor-pointer
}

.contact-form{
    @apply py-5 px-3 font-light bg-[#FAF8F4] rounded-xl focus:outline-teal-950
}